* {
  text-rendering: optimizeLegibility;
}

/* :root {
  --first-color-dark-blue: #002967;
  --second-color-blue: #0089cb;
  --third-color-yellow: #F9AE00;
  --fourth-color-bg-light-blue: #eef9fc;
  --fifth-color-bg-blue: rgba(0, 137, 203, 0.2);
  --sixth-color-bg-yellow: rgba(249, 174, 0, 0.2);
} */
:root {
  --bs-body-font-size: 0.75rem !important;
  --first-dark-blue: #002967;
  --second-yellow: #f9ae00;
  --third-blue: #0089cb;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  font-style: normal;
  text-rendering: optimizeLegibility;
  color: #002d72;
}

.brand-yellow-color {
  color: #ffe500 !important;
}

.brand-blue-color {
  color: #1a3c7b !important;
}

body {
  /* background-image: url(assets/images/wallpaper.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.heading {
  font-weight: 700 !important;
  color: #001432;
  font-size: 1.4rem !important;
}

.promo-note {
  line-height: 1 !important;
}

.qty {
  width: auto !important;
}

.select-qty {
  width: 80px !important;
  min-width: 80px !important;
}

.css-87zgjs input {
  border: 1px solid #bfb5b5 !important;
}

.css-87zgjs input:focus {
  border-color: gray !important;
  /* Ensure border color stays gray on focus */
  outline: none !important;
  /* Remove default focus outline */
}

.select-qty button {
  padding: 0.1rem 0.2rem !important;
}

.select-qty input {
  height: auto !important;
  width: auto !important;
  min-width: 50px !important;
}

.project-heading {
  font-size: 1.6rem !important;
  font-weight: 700 !important;
}

.project-subheading {
  font-size: 1.2rem !important;
  color: #f9ae00 !important;
  font-weight: 800 !important;
}

.login-form-sec {
  min-height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form-box {
  width: auto;
  max-width: 400px;
  padding: 40px;
  border-radius: 0.37rem;
  backdrop-filter: blur(6px) saturate(180%);
  -webkit-backdrop-filter: blur(6px) saturate(180%);
  background-color: rgba(238, 249, 252, 0.75);
  border: 1px solid rgba(209, 213, 219, 0.3);
  box-shadow: 1px 2px 4px #0087cb27;
}

.react-confirm-alert-overlay {
  background: rgb(28 25 25 / 68%) !important;
}

.react-confirm-alert-body {
  font-size: 18px;
  color: #000 !important;
  text-align: center !important;
  font-family: Lato;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
  font-size: 24px !important;
  font-family: Lato, sans-serif !important;
  font-weight: 800;
}

.react-confirm-alert-button-group {
  text-align: center !important;
  display: block !important;
}

.react-confirm-alert-button-group > button {
  font-size: 15px !important;
  margin-right: 0.4rem !important;
}

.react-tooltip-lite {
  background: #fff !important;
  color: #2a2626 !important;
  max-width: 500px !important;
  border: 1px solid #707078 !important;
}

.selectInputBox .searchBox {
  height: 25px !important;
  margin-top: unset !important;
  width: 150px !important;
}

.highlightOption {
  background: #fff !important;
  color: #000 !important;
}

.multiSelectContainer li {
  padding: unset !important;
}

.mobileMenu {
  position: fixed;
  top: 52px;
  right: 0;
  z-index: 10;
  background: #c9c9c9;
}

.logo-sidebar {
  margin-left: 85px;
}

.FormField {
  margin: 0 0 0rem 0 !important;
}

.landing-content {
  width: 500px !important;
  min-height: 500px;
  height: auto !important;
  margin: 0 auto !important;
}

.landing-info {
  min-height: 100% !important;
  /* background: linear-gradient(120deg, rgba(0, 59, 126, 0.4), rgb(203 37 0 / 80%)), url(../src/assets/images/office-banner.webp) !important; */
  background-size: cover !important;
  background-position: center !important;
  padding: 50px !important;
}

.fnameLabel {
  font-size: 45px !important;
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.lnameLabel {
  font-size: 30px !important;
  margin-left: 10px;
}

.center {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(224, 217, 217, 0.3);
}

.spinner svg {
  width: 100px !important;
}

.margin-left {
  margin-left: 70px !important;
}

.css-adylpt {
  color: #fff;
}

.login {
  width: 500px;
  height: auto;
  border: 2px solid #d1d1d1;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 15px;
  display: flex;
  margin: 0px auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.login-div {
  width: 100%;
}

.tbl-content {
  position: relative;
  height: auto;
  overflow: auto;
  display: block !important;
  /* margin-top: 15px; */
  /* height: 450px !important; */
}

.m-modal__panel--animation-enter-done {
  background-color: #665f5fb7 !important;
  z-index: 9999;
}

.react-datepicker-popper {
  transform: inherit !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.modalPopup {
  border-radius: 4px !important;
  border: 4px solid #fff !important;
  background: rgba(0, 0, 0, 0.4) !important;
  box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a !important;
}

.modelHeader {
  min-height: unset !important;
  border: unset !important;
  border-bottom: 1px solid #002967 !important;
}

.modelHeader .svg-inline--fa {
  color: #002967 !important;
  cursor: pointer;
}

.pager {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  color: #5e6776;
  border: 1px solid #607d8b;
  padding: 0;
}

.css-190m7f3:nth-of-type(even) {
  background-color: #d6dde3 !important;
}

.searchBar label {
  padding-top: 10px;
  padding-bottom: 5px;
}

.pager > li {
  background-color: #fff;
  text-align: center;
  border-right: 1px solid #607d8b;
  padding: 8px 12px;
  list-style-type: none;
  cursor: pointer;
  line-height: 1;
}

.pager > li:last-child {
  border: none;
}

.pager > li.active {
  background-color: rgb(0, 45, 114);
  color: #fff;
  font-weight: bold;
}

header .dropdown-content svg {
  margin-right: 0.6rem;
  float: left;
}

.tbl-content2 {
  overflow: auto !important;
}

table input[type="checkbox"] {
  height: auto !important;
}

.burgerMenu button {
  background-color: #0089cb !important;
  padding: 0.6rem !important;
}

.burgerMenu button svg path {
  color: #fff !important;
}

.searchButton {
  margin-top: 0 !important;
  text-align: center;
  display: block !important;
  height: auto;
}

.searchButton div div {
  margin-bottom: 20px !important;
}

.menu2 {
  font-size: 13px !important;
  height: auto;
  min-height: auto;
}

/* .menu2 svg {
  margin-right: 0.5rem;
  width: calc(1.5 * 0.7rem);
} */

header .menu2 {
  margin: 0 0.3rem 0 0 !important;
  border-radius: 6px;
  font-weight: 500;
  padding: 0.3rem 0.8rem !important;
  color: #1a3c7b;
  z-index: 99999;
}

header .menu2:hover {
  background-color: #f2f4f8 !important;
  color: #33578e !important;
}

.active.menu2 {
  background-color: #1a3c7b;
  border: 1px solid #fff;
  font-weight: 500;
  box-shadow: 0 0 0 2px #0089cb21;
  color: #fff !important;
}

.navMenu {
  display: flex;
  align-items: center;
}

/* header .metro-logo g path:first-child {
  fill: #1A3C7B;
} */

table td p {
  margin: 0;
}

.custom-btn.css-q4xxd2 {
  background-color: #0089cb;
}
.dropbtn {
  z-index: 999999 !important;
}

/* table td button {
  margin-right: .5rem !important;
} */

.selectBox {
  min-width: 150px;
  font-size: 14px !important;
  background-color: #eef9fc !important;
  box-shadow: 1px 2px 2px #00000029 !important;
  background-size: 16px 12px !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.375rem !important;
}

select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

.tableborder {
  border: 1px solid #c7c0c0 !important;
}

.tableborder td {
  border: 1px solid #c7c0c0 !important;
}

.tableborder th {
  border: 1px solid #c7c0c0 !important;
}

.trowColor {
  background-color: #b9daf3;
}

.trowColor2 {
  background-color: #fff;
}

.summary-item {
  margin: 0;
  background-color: #fff;
  border: 1px solid #ffe500;
  padding: 0.6rem !important;
  min-height: 55px;
  border-radius: 0.4rem !important;
  box-shadow: 2px 4px 8px -4px rgba(0, 0, 0, 0.1);
}

.summary-item-title {
  font-size: 0.8em !important;
  color: #666 !important;
  font-weight: 600 !important;
  color: #666666;
  margin-bottom: 0.3rem !important;
  line-height: 1 !important;
}

.summary-item-icon .svg-inline--fa {
  font-size: 1.8rem;
  color: #002967;
  margin-right: 0.5rem;
}

.summary-item-value {
  font-weight: 600 !important;
  margin: 0 !important;
  color: #1a3c7b !important;
  font-size: 80% !important;
  line-height: 1.2 !important;
}

.order-detail-item {
  display: block !important;
  padding: 0.2rem 0.3rem 0.2rem 0.2rem;
  min-height: 40px;
  border-left: 2px solid #baddf6;
  background-color: #eef9fc;
}

.order-detail-item_title {
  color: #666;
  font-size: 0.8rem;
}

.order-detail-item_value {
  font-size: 90%;
  color: #002967;
  font-weight: 600;
}

.mds-segmented-control-option > div,
.mds-segmented-control-option > div:hover,
.mds-segmented-control-option:hover {
  display: flex;
  text-decoration: none !important;
}

select option {
  padding: 5rem !important;
}

.margin-top-20 {
  margin-top: 20px;
}

input,
select {
  font-size: 0.8rem !important;
  line-height: 1.4rem !important;
  height: 2.2rem !important;
  border-radius: 0.37rem !important;
}

.css-pw4ld2 {
  width: 100% !important;
}

.css-pw4ld2 input {
  padding-right: 0rem !important;
}

.m-input_wrapper {
  border: 0 !important;
}

select {
  padding: 0 2rem 0 0.75rem !important;
}

select ~ svg {
  height: 2.2rem !important;
  width: calc(1.5 * 0.8rem) !important;
}

.searchButton {
  margin-left: 0.6rem;
}

.login info {
  font-size: 1.5rem !important;
  margin-bottom: 0.4rem !important;
}

label {
  font-size: 0.8rem !important;
  line-height: 1 !important;
  font-size: 14px !important;
  color: #666666 !important;
}

label span {
  line-height: 1;
  margin: 0 0.2rem;
}

header + div {
  padding: 0 !important;
}

.css-s75cky {
  height: auto !important;
  width: 8rem !important;
}

.css-ufpobh,
.metro-logo {
  display: flex !important;
  align-items: center !important;
  min-height: 32px;
}

.projectName .firstName {
  color: #1a3c7b;
  font-weight: 800;
  line-height: 1.2;
  font-size: 14px;
}

.projectName .secName {
  color: #f9ae00;
  font-weight: 800;
  line-height: 1.2;
  font-size: 14px;
}

.metro-logo svg {
  top: unset !important;
}

.header .css-18v8xbq {
  margin-right: 10px !important;
}

.table-bg-color {
  background-color: #fff1b9;
  /* color: #fff; */
}

.header + div {
  padding: 0 1rem !important;
}

.datepicker {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #ffffff;
  box-shadow: 0 0 0 1px #ccd0d6;
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: inherit;
  padding: 0 0.75rem;
  height: 2.5rem;
  color: #001432;
  border-radius: 0.125rem;
  -webkit-transition: 0.125s ease-in-out 0s;
  transition: 0.125s ease-in-out 0s;
  -webkit-transition-property: border-color;
  transition-property: border-color;
}

.buttonIcon {
  max-width: 16px;
}

.pager > li {
  background-color: #c8e4f8;
  border: unset !important;
  border-radius: 6px;
  margin: 0 0.2rem;
}

.pager {
  border: unset !important;
  margin: 0;
}

.searchBar {
  margin-top: 15px;
}

.filter-link {
  color: #0089cb !important;
  text-decoration: none !important;
  font-weight: 700 !important;
}

.m-checkbox__icon {
  border-color: #0089cb !important;
  box-shadow: 0 0 0 1px #0089cb !important;
  background-color: #0089cb !important;
}

.text-danger,
label .danger {
  color: #e52d12 !important;
}

.text-success {
  color: green !important;
}

.action-btn .fa-xmark {
  color: #e52d12;
}

.action-btn .fa-pen {
  color: #1a3c7b;
}

.colored-input input {
  background-color: #eef9fc;
  box-shadow: inset 0 0 6px -2px #0087cb6f;
}

.tb-action-btn {
  padding: 0.1rem !important;
  border: 2px solid #0089cb !important;
  box-shadow: unset !important;
  height: 1.8rem !important;
  width: 1.8rem !important;
  margin-top: 0 !important;
}

.tb-action-btn .fa-pen {
  color: #0089cb;
}

.tb-action-btn:hover,
.tb-action-btn:focus {
  background-color: #eef9fc !important;
  border: 1px solid #baddf6 !important;
}

.tb-action-btn .svg-inline--fa {
  color: #0089cb;
}

.tb-action-btn .fa-xmark {
  color: #e52d12;
}

.tab-panels div[role="tabpanel"] {
  border-top: unset !important;
  padding: unset !important;
}

.form .login {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
  max-width: 400px;
  padding: 1.2rem;
  border: unset;
}

.form label {
  line-height: 1 !important;
}

.form label,
.form label span {
  line-height: 1 !important;
}

.filter-box {
  padding: 0.8rem 1rem !important;
}

.bg-box {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 1px 2px 2px #00000010 !important;
  border: 2px solid #d2d2d2ad !important;
  border-radius: 6px !important;
}

.input-group {
  margin-left: 5px;
}

.input-group-text {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5 !important;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}

.form-control-table {
  width: 100% !important;
}

.form-class-next-year {
  float: left;
  width: 80% !important;
}

.form-class-cc-fsd {
  float: left;
  width: 65% !important;
}

.text-center {
  text-align: center !important;
}

.selectBox2 {
  max-width: 85px !important;
  min-width: 85px !important;
}

.editableInput {
  max-width: 180px !important;
  min-width: 180px !important;
}

.DayPickerInput {
  display: inline-block;
  /* z-index: 9999999; */
}

.DayPickerInput input {
  width: 100% !important;
}

/* input {
   border: 1px solid #ccd0d6 !important; 
   padding: 0 .575rem !important; 
}  */

.toolTip {
  margin-left: 5px;
}

.target {
  text-decoration: underline;
  cursor: pointer;
}

.target .react-tooltip-lite {
  cursor: default;
}

.flex-spread {
  display: flex;
  justify-content: space-between;
}

.tip-heading {
  margin: 0 0 10px;
}

.sub-box-container .sub-heading {
  font-weight: 600 !important;
  font-size: 1rem !important;
  line-height: 1 !important;
}

.tip-list {
  margin: 0;
  padding: 0 0 0 15px;
}

.tip-list li {
  margin: 5px 0;
  padding: 0;
}

/* tooltip styles */
.react-tooltip-lite {
  background: #333;
  color: white;
  max-width: 500px !important;
}

.react-tooltip-lite a {
  color: #86b0f4;
  text-decoration: none;
}

.react-tooltip-lite a:hover {
  color: #4286f4;
}

.react-tooltip-lite-arrow {
  border-color: #333;
}

/* overrides with a custom class */
.customTip .react-tooltip-lite {
  border: 1px solid #888;
  background: #ccc;
  color: black;
}

.customTip .react-tooltip-lite-arrow {
  border-color: #444;
  position: relative;
}

.customTip .react-tooltip-lite-arrow::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  z-index: 99;
  display: block;
}

.customTip .react-tooltip-lite-up-arrow::before {
  border-top: 10px solid #ccc;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  left: -10px;
  top: -11px;
}

.customTip .react-tooltip-lite-down-arrow::before {
  border-bottom: 10px solid #ccc;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  left: -10px;
  bottom: -11px;
}

.customTip .react-tooltip-lite-right-arrow::before {
  border-right: 10px solid #ccc;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  right: -11px;
  top: -10px;
}

.customTip .react-tooltip-lite-left-arrow::before {
  border-left: 10px solid #ccc;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  left: -11px;
  top: -10px;
}

.imageWrapper {
  margin: 50px 0 0;
  position: relative;
}

.imageWrapper img {
  width: 500px;
  height: 500px;
}

.controlled-example {
  max-width: 250px;
}

.controlled-example_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid #fff;
}

.controlled-example_close-button {
  cursor: pointer;
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  padding: 0;
}

.controlled-example_close-button:hover {
  color: grey;
}

.internal-scroll-container {
  height: 200px;
  overflow: auto;
}

.internal-scroll-container > div {
  padding-top: 100px;
  height: 400px;
}

.arrow-content-tooltip .react-tooltip-lite {
  box-sizing: border-box;
  border: 1px solid gray;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
}

.dropdown {
  position: relative;
  display: inline-block;
  margin: 0px !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 200px;
  z-index: 10;
  background: #eef9fc;
  border-radius: 6px;
  box-shadow: 0 5px 36px rgba(0, 27, 68, 0.05);
  height: auto;
  max-height: calc(100vh - 140px);
  position: absolute;
  transition: 0.3s opacity 0s, 0.3s transform 0s;
  width: auto;
  border: 2px solid #baddf6;
}

.dropdown-content a {
  color: #002d72;
  padding: 12px;
  text-decoration: none;
  width: -moz-fit-content;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 6px;
}

.dropdown-content a:hover {
  background-color: #baddf6;
  color: #001432;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.custom-pagination .text {
  color: #666;
  margin: auto 0.4rem !important;
}

.custom-pagination .btn {
  background-color: #d9d9d9;
  color: #0089cb;
  border-radius: 50%;
  display: block;
  padding: 0.3rem !important;
  line-height: 1;
}

.custom-pagination .btn-icon {
  width: 0.8rem;
  height: 0.8rem;
  display: inline-block;
}

.custom-pagination .form-select {
  border: 2px solid #baddf6;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
}

.custom-pagination .form-select:hover,
.custom-pagination .form-select:focus {
  border: 2px solid #baddf6;
  box-shadow: unset;
}

.tab-panels,
.box-container {
  border-radius: 0.37rem;
  background-color: rgba(238, 249, 252, 0.9);
  border: 2px solid #baddf6;
  padding: 0.8rem;
  min-height: 50vh;
}

.box-container {
  background-color: rgba(238, 249, 252, 0.7) !important;
}

.tabs button {
  width: auto;
  flex-grow: unset;
  align-self: center;
  line-height: 1.4;
  font-size: 0.9rem;
  min-width: auto;
  text-wrap: nowrap;
  height: auto;
  box-shadow: unset;
  border: 0;
}

table thead th {
  background-color: #c4e1f5 !important;
  color: #1a3c7b !important;
  border-bottom: 2px solid #003b7e38 !important;
}

table tbody tr {
  border-bottom: 1px solid #f0f0f0;
  border-top: 0 !important;
  text-align: center !important;
  border-color: #f0f0f0 !important;
}

tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}

.tabs-group .tabs {
  margin-bottom: 0.8rem;
}

.tabs-group .tabs button {
  background-color: #baddf6;
  border-radius: 1rem;
  border: 2px solid #6689b233 !important;
  padding: 0.3rem 1rem;
  font-weight: 600;
}

.tabs-group .tabs button[aria-selected="true"] {
  border: 2px solid #baddf6 !important;
  background-color: #eef9fc;
}

.tabs-group .tab-separator {
  margin: 0 0.4rem !important;
  height: unset;
  border: unset;
}

.btn {
  cursor: pointer !important;
  border-radius: 0.4rem !important;
}

.btn-primary {
  background-color: #002967 !important;
  color: #fff !important;
}

.btn-primary-outline {
  box-shadow: unset !important;
  color: #002967 !important;
  border: 1px solid #002967 !important;
  background-color: #eef9fc !important;
}

.btn-primary-outline:hover {
  background-color: #b9daf3 !important;
}

.border-0 td {
  border-bottom: 0;
}

.select-input div button {
  font-size: 0.9rem !important;
  width: 100%;
  line-height: 1.4;
  padding: 0.4rem 0.6rem;
}

.select-input svg[data-testid="chevron"] {
  width: calc(1.2 * 1rem) !important;
  height: 2.2rem;
}

.badge.bg-primary {
  background-color: #eef9fc !important;
  color: #002967;
  border: 1px solid #002967;
  line-height: 1 !important;
}

.sub-box-container,
.status-timeline-box {
  border: 2px solid #6689b22b;
  box-shadow: 1px 2px 4px 1px rgba(186, 221, 246, 0.2);
  border-radius: 0.37rem;
}

.sub-box-container {
  background-color: #fff;
  padding: 0.6rem !important;
}

.status-timeline-box {
  background-color: #fffdea;
  min-height: 80px;
}

.first-color-dark-blue {
  background-color: var(--first-color-dark-blue);
  color: #fff;
}

.second-color-blue {
  background-color: var(--second-color-blue);
  color: #fff;
}

.third-color-yellow {
  background-color: var(--third-color-yellow);
  color: #fff;
}

.fourth-color-bg-light-blue {
  background-color: var(--fourth-color-bg-light-blue);
}

.fifth-color-bg-blue {
  background-color: var(--fifth-color-bg-blue);
}

.sixth-color-bg-yellow {
  background-color: var(--sixth-color-bg-yellow);
}

ul.status-timeline,
.status-timeline li {
  margin: 0;
  padding: 0;
}

.status-timeline {
  list-style-type: none;
  counter-reset: number;
  position: relative;
  display: block;
  z-index: 2;
  width: 100%;
}

/* 
.status-timeline::before {
  content: '';
  width:100%;
  border-top: 1px solid rgba(122, 192, 229, 1);
  display: inline-block;
  position: absolute;
  top: 50%;
  z-index: -1; 
} */
.status-timeline__item::after {
  content: "";
  width: 100%;
  border-top: 4px solid rgba(122, 192, 229, 1);
  display: inline-block;
  position: absolute;
  top: 50%;
  z-index: -1;
}

.status-timeline__item:first-child {
  width: calc(100% / 5 - 80px);
}

.status-timeline__item:last-child {
  width: calc(100% / 5 - 40px);
}

.status-timeline__item:last-child::after {
  border-top: 1px solid rgba(122, 192, 229, 1);
}

.status-timeline__item {
  position: relative;
  display: inline-block;
  width: calc(100% / 5 + 2.7em);
  /* change width */
}

.status-timeline__item::before {
  display: flex;
  justify-content: center;
  align-items: center;
  counter-increment: number;
  content: counter(number);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: white;
  font-weight: bold;
  transition: all 0.6s ease-in-out;
  box-sizing: border-box;
}

.status-timeline__item:first-child::before {
  background-color: unset;
  border-radius: 0;
  padding: 0.3rem;
  background-repeat: no-repeat;
  background-position: center;
  display: none;
}

.status-timeline__item--active::before {
  /* background-color: rgba(225, 114, 114, .9); */
  border: 2px solid;
  border-color: rgba(0, 0, 0, 0.3);
}

.status-timeline__summary-text {
  position: absolute;
  /* bottom: -1.5rem; */
  font-size: 0.8rem;
  font-weight: 500;
  text-align: center;
  min-width: 75px;
  left: -2.4rem;
  width: 8rem;
}

.status-timeline_na::before {
  background-color: rgba(122, 192, 229, 1);
}

.status-timeline_success::before {
  background-color: #1a3c7b;
}

.status-timeline_success::after {
  background-color: #1a3c7b;
  border-top: 4px solid #1a3c7b;
}

.status-timeline_success:first-child:after {
  border-top: 1px solid #1a3c7b;
}

.status-timeline_pending::before {
  background-color: #7ac0e5;
}

.status-timeline_pending::after,
.status-timeline_pending .status-timeline__summary-text {
  opacity: 0.5;
}

.status-timeline_pending::before {
  background-color: #9dd7f6;
}

.newOrderBtn {
  color: #fff !important;
}

.nextOrderBtn {
  color: #fff !important;
  margin-left: 10px !important;
}

.backOrderBtn {
  color: #002967 !important;
  line-height: 1;
}

.css-kbhlfu {
  box-shadow: 0 0 0 1px #ccd0d6 !important;
}

.radioGroup {
  margin-right: 5px;
  display: inline-flex !important;
}

.m-checkbox__icon {
  background-color: #fff !important;
}

table th {
  text-align: center !important;
  vertical-align: middle !important;
}

table td {
  vertical-align: middle !important;
  padding: 6px !important;
}

table td.text-start,
table th.text-start {
  text-align: start !important;
}

table td.text-end,
table th.text-end {
  text-align: end !important;
}

table td.text-center {
  text-align: center !important;
}

.price {
  text-align: right !important;
}

#customer-agree-no {
  border-radius: 0.37rem 0 0 0.37rem;
  color: #e52d12 !important;
}

#customer-agree-yes {
  border-radius: 0 0.37rem 0.37rem 0;
}

.mds-segmented-control-option {
  font-weight: 700 !important;
  padding: 0.4rem 1rem !important;
}

[for="customer-agree-no"] {
  color: #e52d12 !important;
}

[for="customer-agree-yes"] {
  color: #90c360 !important;
}

.mds-segmented-control-option-isChecked {
  background-color: #baddf6 !important;
  border: 2px solid #6689b233 !important;
  box-shadow: unset !important;
}

.total-price-sec {
  background-color: #ffe60048 !important;
}

.total-price-label {
  font-weight: 800 !important;
  font-size: 115% !important;
}

.total-price-value {
  font-weight: 700;
  background-color: #002967 !important;
  padding: 0.3rem 0.6rem;
  color: #fff !important;
  border-radius: 0.4rem;
  font-size: 105% !important;
}

.dialogModal {
  width: auto !important;
}

.rightAlign {
  margin-right: 0 !important;
  margin-left: auto !important;
}

.modalCloseBtn {
  color: #fff;
}

.ArticleModal {
  width: auto !important;
  max-width: 80% !important;
}

.action-btn,
.action-btn-outline {
  border-radius: 0.4rem !important;
  box-shadow: unset !important;
  padding: 0.6rem !important;
  border: 1px solid #0089cb !important;
}

.action-btn {
  background-color: #0089cb !important;
}

.action-btn svg {
  color: #fff !important;
}

.action-btn-outline {
  background-color: rgba(185, 218, 243, 0.3) !important;
  min-height: 2.2rem !important;
  color: #0089cb !important;
}

.reset-btn .svg-inline--fa {
  color: #e52d12 !important;
}

.custom-btn,
.btn,
.action-btn-outline,
.action-btn {
  padding: 0.5rem 1rem !important;
  line-height: 1 !important;
  font-size: 0.9rem !important;
  font-weight: 600 !important;
}

.custom-btn {
  margin-right: 0.4rem !important;
}

.custom-btn:hover {
  background-color: #70aedf !important;
  color: #fff !important;
}

.btn-danger {
  color: var(--bs-btn-active-color) !important;
  background-color: var(--bs-btn-active-bg) !important;
  border-color: var(--bs-btn-active-border-color) !important;
}

.btn-row button,
.btn-row a {
  margin-right: 0.4rem !important;
  margin-left: 0 !important;
  line-height: 1.1 !important;
}

.btn-row .custom-btn {
  padding: 0.6rem !important;
}

.btn-icon {
  padding: 0.25rem !important;
  line-height: 1 !important;
  height: auto;
  min-height: auto !important;
  border-radius: 50% !important;
}

.btn-icon .svg-inline--fa {
  font-size: 80% !important;
}

.userName {
  font-weight: 500 !important;
}

.project-name {
  color: #666;
  line-height: 1.4;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 700;
}

.project-name div {
  color: #1a3c7b;
  line-height: 1.4;
  font-size: 1.4rem;
  font-weight: 600;
}

table .badge.bg-primary {
  padding: 0.1rem 0.3rem !important;
}

@media only screen and (max-width: 768px) {
  .ArticleModal {
    min-width: 95% !important;
  }
}

.Toastify__toast-container {
  z-index: 9999999999 !important;
}

.react-confirm-alert-overlay {
  z-index: 999999999 !important;
}

.css-1bqneax input {
  padding-right: 0.1rem !important;
}

.css-1gipefd-MuiBackdrop-root {
  z-index: 9999999 !important;
}

.mds-amountpicker-minus-btn,
.mds-amountpicker-plus-btn {
  display: none !important;
}

.order-detail-container {
  background-color: #eef9fc;
  border-radius: 10px;
  padding: 15px !important;
  border-bottom: 1px solid #e0e0e0;
}

.stack-container {
  padding-left: 10px;
}

.order-detail-item_comment {
  font-size: 1rem;
  color: #333;
}

.changedBy {
  font-size: 0.9rem;
  margin-right: 8px;
}

.Date {
  font-size: 0.8rem;
  color: #777;
  text-align: right;
}

.row-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.tt .css-87zgjs {
  width: 60% !important;
}

/* Layout container */
.layout-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

body,
h1,
h2,
p,
ul,
li {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
  margin: 0;
}

/* Sidebar styles */
.sidebar {
  background-color: #f4f4f4;
  padding: 15px;
  border-right: 1px solid #ddd;
}

.sidebar nav ul {
  list-style-type: none;
}

.sidebar nav ul li {
  margin: 10px 0;
}

.sidebar nav ul li a {
  text-decoration: none;
  color: #333;
}

/* Main content styles */

/* Abhijit CSS Start on 6 sept */
*,
div,
span,
p {
  font-family: system-ui !important;
  font-weight: 400;
}

body {
  max-width: 100%;
}

.header-bar {
  background-color: #002967;
}

.logo {
  font-size: 15px;
  font-weight: bold;
  color: #fff;
}
.logo-head-1 {
  color: #f9ae00;
  font-weight: bold;
}
.logo-head-2 {
}

.profile-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

@media (max-width: 768px) {
  .profile-image {
    display: none; /* Hide profile image on smaller screens */
  }

  .header-bar .navbar-nav {
    text-align: center;
  }

  .logo {
    font-size: 1.2rem;
  }
}
.dropdown-menu[data-bs-popper] {
  left: -15px;
}

/* Footer styles */
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #002967;
  color: #fff;
  padding: 10px 0;
}

.text-white {
  color: #fff;
}

.footerLogo {
  max-width: 160px;
  margin-left: 0.4rem;
}

.poweredByFooter {
  margin-right: 50px !important;
}

.helloUser .userName {
  color: #002967;
}

.helloUser svg {
  color: #0059e4;
}

.helloUser .userTitle {
  color: #f9ae00;
}

a[aria-label="Metro App Home Link"] {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 3rem;
}

a[aria-label="Metro App Home Link"] div {
  height: auto !important;
  width: auto !important;
}

.table-main-row {
  background-color: #000;
  color: #fff;
}
.deleteBtn {
  cursor: pointer;
}
.activeMenu,
.activeMenu span {
  color: #f9ae00;
}

.menu,
.menu span {
  color: #fff;
}
.dropdown-menu svg {
  color: #000;
  width: 20px;
}
.react-datepicker-wrapper{
  width: 100% !important;
}
.react-datepicker__tab-loop,
.react-datepicker-popper {
  position: absolute !important;
  z-index: 1050; /* Ensure it's above other elements */
}

.tabClass .nav-item:not(:first-child) {
  margin-left: 5px;
}
.tabClass .nav-link.active{
  background-color: #f9ae00 !important;
  color: #fff;
}

.tabClass .nav-link{
  background-color: #000 !important;
  color: #fff;
}


@media (max-width: 576px) {
  .nav {
    --bs-nav-link-padding-x: 0.3rem;
  }
  .w-25{
    width: 80% !important;
  }
}


@media screen and (max-width: 768px) {
  .left-sidebar {
    width: 14% !important;
  }

  .footer {
    min-height: auto;
  }

  .main-content {
    min-height: 85vh;
    padding: 1rem 1rem;
  }
}

.status-indicator {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
}

.green {
  background-color: green;
}

.orange {
  background-color: orange;
}
.css-18bhljc {
  padding: 0px 1rem !important;
}
.text-align-left {
  text-align: left;
}
.loginForm {
  border: #002967 solid 1px;
  padding: 10px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-body {
  padding: 20px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.cardTitle {
  background: #000;
  color: #fff;
  text-align: center;
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
}
.cardValue {
  color: #000;
  text-align: center;
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
}
